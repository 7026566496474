export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_OP7KAxNnq",
    "USER_POOL_APP_CLIENT_ID": "4ktqbcsbu4pjtr3579kcl9u4qv",
    "ROOT_API_URL": "https://bonf7g1oye.execute-api.us-east-1.amazonaws.com/tinf-dev-exchange-sandbox-documents-api-prod",
    "MFA_ISSUER": "Precedent-tinf-dev-exchange-sandbox",
    "S3_DEPLOYMENT_BUCKET": "tinf-dev-exchange-sandbox-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:959efcec-78a0-431e-95a3-d267d4f0b3e9",
    "RUM_APP_MONITOR_ID": "35442cdb-e0a2-47c8-aaf4-0ea208818f27",
    "ENV_STAGE": "dev-tinf"
}
